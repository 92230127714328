import React from 'react';
import { MainLayout } from '../../components/Layout';
import PartnersList from '../../components/Partners/PartnersList';
import imgMobile from '../../assets/img/partners/mobile-chat.png';
import { Link, withPrefix } from 'gatsby';
import imgBg from '../../assets/img/home/bg-wave.png';

import imgIconChat from '../../assets/img/partners/icon-chat.svg';
import imgIconUp from '../../assets/img/partners/icon-up.svg';
import imgIconLove from '../../assets/img/partners/icon-love.svg';

import ReferencesSection from '../../components/Partners/ReferencesSection';
import Aximonial from '../../assets/img/references/aximonial.png';
import BatimSeine from '../../assets/img/references/seinegestion.png';

export default () => {
  const referenceList = [
    {
      icon: Aximonial,
      content: '',
    },
    {
      icon: BatimSeine,
      content: '',
    },
  ];
  return (
    <MainLayout
      siteTitle={`Syndics de copropriété - Découvrez nos offres`}
      siteDescription={`Découvrez nos offres déstinées aux syndics de copropriété`}
    >
      <PartnersList selectedPartner="syndics" />

      <div
        className="bg-wave"
        style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
      >
        <section className="container mt-5">
          <div className="row">
            <div className="col-6 text-align-center hidden-sm">
              <img
                src={imgMobile}
                alt="Syndic de copropriété"
                className="max-width-400-lg"
              />
            </div>

            <div className="col-4">
              <div className="pl-5 pr-5">
                <div className="roboto font-size-xl bold pt-10">
                  Remontée d'incidents
                </div>
                <div className="text-color-purple-grey pt-5">
                  Facilitez le signalement des incidents par l'occupant et leur
                  résolution grâce à un tableau de bord interactif
                </div>

                <div className="roboto font-size-xl bold pt-10">
                  Diffusion d'informations
                </div>
                <div className="text-color-purple-grey pt-5">
                  Communiquez la bonne information, à la bonne personne, au bon
                  moment, grâce à des groupes de diffusion spécifique
                </div>

                <div className="roboto font-size-xl bold pt-10">
                  Animation locale
                </div>
                <div className="text-color-purple-grey pt-5">
                  Participez à l’enrichissement de la vie des quartiers où sont
                  situées vos résidences grâce à nos animateurs locaux
                </div>

                <div className="ml-auto mr-auto pt-15 text-align-center hidden-sm">
                  <Link
                    to="/contact/"
                    state={{
                      contactType: 'partner',
                      contactSubType: 'syndics',
                    }}
                    className="btn"
                  >
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row hide show-md">
            <div className="col-12 pt-5 pl-6-md pr-6-md text-align-center">
              <img src={imgMobile} alt="Syndic de copropriété" />
            </div>
          </div>

          <div className="row pb-10 hide show-md">
            <div className="col-12 ml-auto mr-auto text-align-center">
              <Link
                to="/contact/"
                state={{ contactType: 'partner', contactSubType: 'syndics' }}
                className="btn"
              >
                Nous contacter
              </Link>
            </div>
          </div>
        </section>
      </div>

      <section className="container mt-10 partners-advantages-list">
        <ul className="text-align-center">
          <li>
            <div className="advantage-icon">
              <img src={imgIconChat} alt="Communiquez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Communiquez</strong> efficacement avec les résidents
            </div>
          </li>
          <li className="ml-10 mr-10">
            <div className="advantage-icon">
              <img src={imgIconUp} alt="Valorisez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Valorisez</strong> votre travail en vous démarquant de vos
              concurrents
            </div>
          </li>
          <li>
            <div className="advantage-icon">
              <img src={imgIconLove} alt="Apaisez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Apaisez</strong> les relations entre les parties prenantes
            </div>
          </li>
        </ul>
      </section>

      <ReferencesSection referenceList={referenceList} />
    </MainLayout>
  );
};
